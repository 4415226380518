import React from "react";
import styled from "./AddCombination.module.scss";
import { useState } from "react";
import clsx from "clsx";
import Icons from "../../Atoms/Icons";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { useEffect } from "react";
import { Checkbox } from "primereact/checkbox";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import swal from "sweetalert";
const AddCombination = () => {
  const Navigate = useNavigate();
  const [selectCitizenOf, setSelectCitizenOf] = useState(null);
  const [selectedVisaType, setSelectedVisaType] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [checked, setChecked] = useState([]);
  const [visaSubTypeChecked, setVisaSubTypeChecked] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const [validty, setValidty] = useState([]);
  const [fee, setFee] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [countries, SetCountries] = useState([]);
  const [visaStatus, setVisaStatus] = useState("3");
  const [visaStatuses, setVisaStatuses] = useState([]);
  const [travelTo, setTravelTo] = useState(null);
  const [options, setOptions] = useState([]);
  const [residentsOf, setResidentsOf] = useState(null);
  const [exceptions, setExceptions] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    GET_API_DOCUMENTS();
    GET_VISA_TYPES();
    GetValidty();
    GetFee();
    GetCountry();
    getVisaStatuses();
    setFields([
      {
        title: "",
        visa_sub_type: "",
        additional_notes: "",
        additional_notes_az: "",
        additional_notes_ru: "",
        documents: [],
        evisa_id: "",
        fees: [],
        processing_value: "",
        related_processing_key: "1",
        validity: "1",
      },
    ]);
  }, []);

  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const GET_API_DOCUMENTS = async () => {
    setLoading(true);
    const API_URL = BASE_URL + "api/v1/documents";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSelectedCategories(data);
        setLoading(false);
      });
  };
  const GET_VISA_TYPES = async () => {
    const API_URL = BASE_URL + "api/v1/visa-types";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setVisaTypes(data);
        setLoading(false);
      });
  };
  const GetValidty = async () => {
    const API_URL = BASE_URL + "api/v1/validities";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setValidty(data);
        setLoading(false);
      });
  };
  const GetFee = async () => {
    const API_URL = BASE_URL + "api/v1/fees";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFee(data);
        setLoading(false);
      });
  };
  const GetCountry = async () => {
    const API_URL = BASE_URL + "api/v1/countries";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        SetCountries(data.data);
        setOptions(
          Object.values(data.data).map((country) => {
            return {
              name: country.name,
              id: country.alpha_3_code,
            };
          }),
        );
        setLoading(false);
      });
  };
  const getVisaStatuses = async () => {
    const API_URL = BASE_URL + "api/v1/combinations/visa-statuses";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setVisaStatuses(data);
      });
  };

  const [form, setForm] = useState({
    travel_to: "",
    travel_from: {},
    visa_type: "",
    visa_status_v2: visaStatus,
    residents_of: [],
    additional_notes: "",
    additional_notes_az: "",
    additional_notes_ru: "",
    documents: [],
    visa_subtypes: [
      {
        title: "",
        visa_sub_type: "",
        additional_notes: "",
        additional_notes_az: "",
        additional_notes_ru: "",
        documents: [],
        evisa_id: "",
        fees: [],
        processing_value: "",
        related_processing_key: "1",
        validity: "1",
      },
    ],
    citizens_of: [],
    exception_residents_of: [],
    all_residents_of: false,
    overwrite: false,
  });
  const SelectCountries = Object.values(countries).map((country) => {
    return {
      name: country.name,
      id: country.alpha_3_code,
    };
  });
  // const options = [...SelectCountries];
  const handleSelectAll = () => {
    setAllSelected(!allSelected);
    if (allSelected) {
      setResidentsOf([]);
      travelTo && travelTo.id !== "ARE"
        ? setExceptions([travelTo])
        : setExceptions([]);
    } else {
      travelTo && travelTo.id !== "ARE"
        ? setExceptions([travelTo])
        : setExceptions([]);
    }
  };

  const handleResidenceSelect = (value) => {
    if (value.length === options.length) {
      setAllSelected(true);
      setResidentsOf([]);
      travelTo && travelTo.id !== "ARE"
        ? setExceptions([travelTo])
        : setExceptions([]);
    } else {
      setResidentsOf(value);
    }
  };

  const handleExceptionSelect = (value) => {
    if (value.length === SelectCountries.length) {
      setAllSelected(false);
      travelTo && travelTo.id !== "ARE"
        ? setExceptions([travelTo])
        : setExceptions([]);
    } else {
      setExceptions(value);
    }
  };

  const handleTravelToSelect = (value) => {
    setTravelTo(value);
    if (value.id !== "ARE") {
      setOptions(SelectCountries.filter((o) => o.id !== value.id));
    }
  };
  // const allSelected =
  //   SelectCountries?.filter((o) => o.id !== travelTo?.id || o.id === "ARE")
  //     .length === residentsOf?.length;

  const visa = visaTypes.map((item) => {
    return {
      name: item.title,
      id: item.id,
    };
  });
  const validity = validty.map((item) => {
    return {
      name: item.title,
      id: item.id,
    };
  });
  const HandleDelete = (index) => {
    const UpdateFields = [...fields];
    UpdateFields.splice(index, 1);
    setFields(UpdateFields);
  };
  const SingleHandleChanges = (e) => {
    const value = e.target.value;
    setForm({
      ...form,
      [e.target.name]: value,
    });
  };
  const handleChnage = (e, index) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    updatedFields[index] = {
      ...updatedFields[index],
      [name]: value,
    };
    form.visa_subtypes = updatedFields;
    setFields(updatedFields);
    setForm({ ...form });
  };

  const handleFeeChange = (e, feeId, index) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    const updateField = updatedFields[index];
    let foundFee = updateField.fees?.find((x) => x.id == feeId);
    if (foundFee == null) {
      if (!updateField.fees) updateField.fees = [];
      updateField.fees.push({
        id: feeId,
        currency: "USD",
        [name]: value,
        is_online: false,
      });
    } else {
      foundFee[name] = value;
    }
    form.visa_subtypes = updatedFields;
    setFields(updatedFields);
    setForm({ ...form });
  };

  const handleFeeChangeOnline = (e, feeId, index) => {
    const { name, checked } = e.target;
    const updatedFields = [...fields];
    const updateField = updatedFields[index];
    let foundFee = updateField.fees?.find((x) => x.id == feeId);

    if (foundFee == null) {
      if (!updateField.fees) updateField.fees = [];
      updateField.fees.push({
        id: feeId,
        currency: "USD",
        is_online: false,
        [name]: checked,
      });
    } else {
      foundFee[name] = checked;
      // foundFee = { ...foundFee, [name]: value };
    }
    form.visa_subtypes = updatedFields;
    setFields(updatedFields);
    setForm({ ...form });
  };

  const onCategoryChangeVisaSubtype = (e, index) => {
    const updatedList = [...visaSubTypeChecked];
    let field = updatedList.find((x) => x.fieldIndex === index);
    if (field == null) {
      updatedList.push({ fieldIndex: index, checked: [] });
      field = updatedList.find((x) => x.fieldIndex === index);
    }
    if (e.target.checked) {
      field.checked.push(e.target.value.id);
    } else {
      field.checked.splice(field.checked.indexOf(e.target.value.id), 1);
    }
    setVisaSubTypeChecked([...updatedList]);
    const visaSubTypes = form.visa_subtypes;
    visaSubTypes[index].documents = field.checked;
    setForm({ ...form, visa_subtypes: visaSubTypes });
  };

  const onCategoryChange = (e, index) => {
    const updatedList = [...checked];
    if (e.target.checked) {
      updatedList.push(e.target.value.id);
    } else {
      updatedList.splice(updatedList.indexOf(e.target.value.id), 1);
    }
    setChecked([...updatedList]);
    setForm({ ...form, documents: updatedList });
  };

  const handleAdd = () => {
    setAddLoading(true);
    const UpdateFields = [
      ...fields,
      {
        title: "",
        visa_sub_type: "",
        additional_notes: "",
        additional_notes_az: "",
        additional_notes_ru: "",
        documents: [],
        evisa_id: "",
        fees: [],
        processing_value: "",
        related_processing_key: "1",
        validity: "1",
      },
    ];
    setFields(UpdateFields);
    setForm({ ...form, visa_subtypes: UpdateFields });
    setTimeout(() => {
      setAddLoading(false);
    }, 2000);
  };
  const OnSubmit = () => {
    setSubmitLoading(true);
    if (!fields.length) {
      swal("Error", `Must add at least one subtype`, "error");
      setSubmitLoading(false);
    } else {
      const newForm = {
        additional_notes: "",
        additional_notes_ru: "",
        additional_notes_az: "",
        travel_to: travelTo?.id,
        documents: checked,
        visa_status_v2: visaStatus,
        visa_type: selectedVisaType?.id.toString(),
        visa_subtypes: [
          {
            title: form?.title,
            visa_sub_type: "",
            additional_notes: "",
            additional_notes_az: "",
            additional_notes_ru: "",
            documents: [],
            evisa_id: "",
            fees: [],
            processing_value: "",
            related_processing_key: "",
            validity: "",
          },
        ],
        overwrite: false,
        travel_from: selectCitizenOf.reduce((accumulator, currentItem) => {
          accumulator[currentItem.id] = {
            all: allSelected,
            exceptions: exceptions.map((e) => e.id) || [],
            residents_of: residentsOf.map((r) => r.id) || [],
          };
          return accumulator;
        }, {}),
      };
      form.travel_to = newForm.travel_to;
      form.citizens_of = newForm.citizens_of;
      form.residents_of = newForm.residents_of;
      form.exception_residents_of = newForm.exception_residents_of;
      form.visa_status_v2 = newForm.visa_status_v2;
      form.visa_type = newForm.visa_type;
      form.all_residents_of = newForm.all_residents_of;
      form.travel_from = newForm.travel_from;

      setForm({ ...newForm });

      const headers = {
        Authorization: "Token" + " " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      axios
        .post(
          BASE_URL + `api/v1/combinations`,
          { ...form },
          {
            headers,
          },
        )
        .then((res) => {
          setSubmitLoading(false);
          if (res.status == 201) {
            setSubmitLoading(false);

            toast.success("Combination added successfully");
            Navigate("/combination");
          }
          if (res.status == 500) {
            toast.error("Something went wrong");
          }
          if (res.status == 400) {
            alert("UPDATE");
          }
        })
        .catch((err) => {
          setSubmitLoading(false);
          toast.error(err.response?.data?.non_field_errors[0]?.toString());
          toast.error("Something went wrong");
          if (
            err.response?.data?.non_field_errors[0]
              ?.toString()
              .includes("already exists")
          ) {
            confirmAlert({
              title: (
                <h1 style={{ fontSize: "14px", lineHeight: "25px" }}>
                  {err.response?.data?.non_field_errors[0]?.toString()}
                </h1>
              ),
              message: "Are you sure to overwrite?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    axios
                      .post(
                        BASE_URL + `api/v1/combinations`,
                        { ...form, overwrite: true },
                        {
                          headers,
                        },
                      )
                      .then((res) => {
                        if (res.status == 201) {
                          Navigate("/combination");
                          toast.success("Combination Overwrite successfully");
                        }
                      });
                  },
                },
                {
                  label: "No",
                  onClick: () => {
                    setSubmitLoading(false);
                  },
                },
              ],
            });
          }
        });
    }
  };

  return (
    <section className={styled.add_combination}>
      <div className="container py-4 ">
        <div className="row">
          <div className="col-xl-12">
            <h2>Visa info Combinations</h2>
          </div>{" "}
          <div className="row">
            <div className="col-xl-12">
              <div className={styled.add_form}>
                <form>
                  <div className="row align-items-center">
                    <div className="col-xl-6">
                      <label htmlFor="travel_to">Travel To</label>
                      <Dropdown
                        value={travelTo}
                        {...register("travel_to", { required: true })}
                        onChange={(e) => handleTravelToSelect(e.value)}
                        options={SelectCountries}
                        name="travel_to"
                        optionLabel="name"
                        placeholder="Select a Country"
                        className="w-full w-100"
                        filter
                      />
                      {errors.travel_to && (
                        <p className="error_fields">This field is required</p>
                      )}
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="citizen_of">Citizen Of</label>
                      <MultiSelect
                        value={selectCitizenOf}
                        {...register("citizens_of", { required: true })}
                        onChange={(e) => {
                          setValue("citizens_of", e.value, {
                            shouldValidate: true,
                          });
                          setSelectCitizenOf(e.value);
                        }}
                        options={options.filter((o) => o.id !== travelTo?.id)}
                        optionLabel="name"
                        placeholder="Select Countries"
                        maxSelectedLabels={3}
                        className="w-full w-100"
                        filter
                        display="chip"
                      />
                      {errors.citizens_of && (
                        <p className="error_fields">This field is required</p>
                      )}
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="travel_to">Visa Type</label>
                      <Dropdown
                        {...register("visa_type", { required: true })}
                        value={selectedVisaType}
                        onChange={(e) => setSelectedVisaType(e.value)}
                        options={visa}
                        optionLabel="name"
                        placeholder="Select a visa type"
                        className="w-full w-100"
                        filter
                        showClear
                      />
                      {errors.visa_type && (
                        <p className="error_fields">This field is required</p>
                      )}
                    </div>
                    <div className="col-xl-6">
                      <div className={styled.residence}>
                        {allSelected ? (
                          <label htmlFor="citizen_of">
                            {" "}
                            Select Exception Residents Of
                          </label>
                        ) : (
                          <label htmlFor="citizen_of">Residence Of</label>
                        )}
                        <div className="checked">
                          <input
                            type="checkbox"
                            checked={allSelected}
                            onChange={handleSelectAll}
                          />
                          <label>{allSelected ? " All" : "Select All"}</label>
                        </div>
                      </div>

                      <div>
                        {allSelected ? (
                          <MultiSelect
                            value={exceptions}
                            options={SelectCountries}
                            onChange={(e) => handleExceptionSelect(e.value)}
                            filter
                            optionLabel="name"
                            placeholder="Select Exception Residents Of"
                            maxSelectedLabels={3}
                            className="w-full w-100 my-3"
                            display="chip"
                          />
                        ) : null}
                      </div>
                      {allSelected ? null : (
                        <div>
                          <MultiSelect
                            value={residentsOf}
                            {...register("residence_of", { required: true })}
                            onChange={(e) => {
                              setValue("residence_of", e.value, {
                                shouldValidate: true,
                              });
                              handleResidenceSelect(e.value);
                            }}
                            options={options}
                            filter
                            optionLabel="name"
                            placeholder="Select Countries"
                            maxSelectedLabels={3}
                            className="w-full w-100"
                          />
                          {errors.residence_of && (
                            <p className="error_fields">
                              This field is required
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-xl-12 py-4">
                      <label htmlFor="citizen_of">Visa Status</label>

                      <div className={styled.all_radio_list}>
                        {visaStatuses.map((label, index) => (
                          <div
                            onChange={(e) => setVisaStatus(label.id.toString())}
                            key={index}
                            className={clsx("form-check", styled.select_radio)}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="visa_status_v2"
                              value={label.value}
                              id={label.id}
                              checked={label.id.toString() === visaStatus}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={label.id}
                            >
                              {label.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className={styled.add_additions}>
                <div className={styled.additional_list}>
                  <div className="row my-4 py-4">
                    <div className="col-xl-6  ">
                      <div className={styled.lang_text}>
                        <nav>
                          <div
                            className="nav nav-tabs"
                            id="nav-tab"
                            role="tablist"
                          >
                            <button
                              className="nav-link active"
                              id="nav-home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-home"
                              type="button"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            >
                              English
                            </button>
                            <button
                              className="nav-link"
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              Russian
                            </button>
                            <button
                              className="nav-link"
                              id="nav-contact-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-contact"
                              type="button"
                              role="tab"
                              aria-controls="nav-contact"
                              aria-selected="false"
                            >
                              Azerbaijan
                            </button>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                            tabIndex="0"
                          >
                            <textarea
                              onChange={SingleHandleChanges}
                              value={form?.additional_notes}
                              className="m-0"
                              name="additional_notes"
                            ></textarea>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                            tabIndex="0"
                          >
                            <textarea
                              onChange={SingleHandleChanges}
                              value={form?.additional_notes_ru}
                              name="additional_notes_ru"
                              className="m-0"
                            ></textarea>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-contact"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                            tabIndex="0"
                          >
                            <textarea
                              onChange={SingleHandleChanges}
                              value={form?.additional_notes_az}
                              name="additional_notes_az"
                              className="m-0"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 offset-xl-1">
                      <h5> Documents Lists</h5>
                      <ul className={styled.documents_lists}>
                        <li>
                          {loading ? (
                            <ThreeDots
                              height="30"
                              width="50"
                              radius="9"
                              color="#007bff"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          ) : (
                            selectedCategories &&
                            selectedCategories.map(
                              (category, categoryIndex) => {
                                return (
                                  <React.Fragment key={category.id}>
                                    <div className="flex align-items-center my-2">
                                      <Checkbox
                                        inputId={category.key}
                                        name="category"
                                        value={category}
                                        onChange={onCategoryChange}
                                        className="mx-2"
                                        checked={checked.includes(category.id)}
                                      />
                                      <label
                                        htmlFor={category.key}
                                        className="m-0"
                                      >
                                        {category.title}
                                      </label>
                                    </div>
                                  </React.Fragment>
                                );
                              },
                            )
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {fields &&
            fields.map((el, index) => (
              <div key={index} className="row">
                <div className="col-xl-12">
                  <div className={styled.add_additions}>
                    <div className={styled.additional_list}>
                      <button onClick={() => HandleDelete(index)}>
                        Delete <Icons name="times" />
                      </button>
                      <div className="row  align-items-baseline">
                        <div className="col-xl-6">
                          <div className="row align-items-center">
                            <div className="col-xl-4">
                              <label htmlFor={`title-${index}`}>
                                VISA SUBTYPE
                              </label>
                              <input
                                {...register("title", {
                                  required: true,
                                })}
                                value={
                                  form?.visa_subtypes[index][`title-${index}`]
                                }
                                onChange={(e) => handleChnage(e, index)}
                                name="title"
                                type="text"
                              />
                              {errors.title && (
                                <p className="error_fields">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="col-xl-8">
                              <label htmlFor="processing_time">
                                PROCESSING TIME
                              </label>
                              <div className={styled.processing_time}>
                                <input
                                  {...register("processing_value", {
                                    required: true,
                                  })}
                                  value={
                                    form.visa_subtypes[index][
                                      `processing_value_${index}`
                                    ]
                                  }
                                  name="processing_value"
                                  onChange={(e) => handleChnage(e, index)}
                                  type="text"
                                />

                                <select
                                  name="related_processing_key"
                                  value={
                                    form.visa_subtypes[index][
                                      `related_processing_key_${index}`
                                    ]
                                  }
                                  onChange={(e) => handleChnage(e, index)}
                                >
                                  <option value="1">Buisness Days</option>
                                  <option value="2">Calenar Days</option>
                                  <option value="3">Working Hours</option>
                                  <option value="4">Weeks</option>
                                </select>
                              </div>
                              {errors.processing_value && (
                                <p className="error_fields">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="col-xl-6">
                              <label htmlFor="processing_time">VALIDITY</label>
                              <select
                                name="validity"
                                value={
                                  form.visa_subtypes[index][`validity_${index}`]
                                }
                                onChange={(e) => handleChnage(e, index)}
                              >
                                {validity.map((validty) => (
                                  <option value={validty.id}>
                                    {validty.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-xl-6">
                              <label htmlFor={`evisa_id_${index}`}>
                                E_VISA ID
                              </label>
                              <input
                                type="number"
                                onChange={(e) => handleChnage(e, index)}
                                name="evisa_id"
                              />
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-xl-12">
                              <div className={styled.lang_text}>
                                <nav>
                                  <div
                                    className="nav nav-tabs"
                                    id={`nav-tab-${index}`}
                                    role="tablist"
                                  >
                                    <button
                                      className="nav-link active"
                                      id={`nav-eng-tab-${index}`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-eng-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-eng-${index}`}
                                      aria-selected="true"
                                    >
                                      English
                                    </button>
                                    <button
                                      className="nav-link"
                                      id={`nav-rus-tab-${index}`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-rus-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-rus-${index}`}
                                      aria-selected="false"
                                    >
                                      Russian
                                    </button>
                                    <button
                                      className="nav-link"
                                      id={`nav-az-tab-${index}`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-az-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-az-${index}`}
                                      aria-selected="false"
                                    >
                                      Azerbaijan
                                    </button>
                                  </div>
                                </nav>
                                <div
                                  className="tab-content"
                                  id="nav-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id={`nav-eng-${index}`}
                                    role="tabpanel"
                                    aria-labelledby={`nav-eng-${index}`}
                                    tabIndex="0"
                                  >
                                    <textarea
                                      value={
                                        form.visa_subtypes[index][
                                          `additional_notes-${index}`
                                        ]
                                      }
                                      onChange={(e) => handleChnage(e, index)}
                                      className="m-0"
                                      name="additional_notes"
                                    ></textarea>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id={`nav-rus-${index}`}
                                    role="tabpanel"
                                    aria-labelledby={`nav-rus-${index}`}
                                    tabIndex="0"
                                  >
                                    <textarea
                                      value={
                                        form.visa_subtypes[index][
                                          `additional_notes_ru-${index}`
                                        ]
                                      }
                                      onChange={(e) => handleChnage(e, index)}
                                      name="additional_notes_ru"
                                      className="m-0"
                                    ></textarea>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id={`nav-az-${index}`}
                                    role="tabpanel"
                                    aria-labelledby={`nav-az-${index}`}
                                    tabIndex="0"
                                  >
                                    <textarea
                                      value={
                                        form.visa_subtypes[index][
                                          `additional_notes_az-${index}`
                                        ]
                                      }
                                      onChange={(e) => handleChnage(e, index)}
                                      name="additional_notes_az"
                                      className="m-0"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <h5> Documents Lists</h5>
                          <ul className={styled.documents_lists}>
                            <li>
                              {loading ? (
                                <ThreeDots
                                  height="30"
                                  width="50"
                                  radius="9"
                                  color="#007bff"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                selectedCategories &&
                                selectedCategories.map(
                                  (category, index_category) => {
                                    return (
                                      <div
                                        key={category.id}
                                        className="flex align-items-center my-2"
                                      >
                                        <Checkbox
                                          inputId={category.key}
                                          name="category"
                                          value={category}
                                          onChange={(e) =>
                                            onCategoryChangeVisaSubtype(
                                              e,
                                              index,
                                            )
                                          }
                                          className="mx-2"
                                          checked={visaSubTypeChecked
                                            .find((x) => x.fieldIndex === index)
                                            ?.checked.includes(category.id)}
                                        />
                                        <label
                                          htmlFor={category.key}
                                          className="m-0"
                                        >
                                          {category.title}
                                        </label>
                                      </div>
                                    );
                                  },
                                )
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row my-4 py-4">
                        {fee &&
                          fee.map((fee, feeIndex) => (
                            <div key={feeIndex} className="col-xl-6 my-1">
                              <label htmlFor="">{fee.title || ""}</label>
                              <div
                                className={clsx(
                                  "input-group mb-3",
                                  styled.input_group,
                                )}
                              >
                                <span className="input-group-text">$</span>
                                <input
                                  type="text"
                                  value={form.fees}
                                  onChange={(e) =>
                                    handleFeeChange(e, fee.id, index)
                                  }
                                  name={`value`}
                                  aria-label="Amount (to the nearest dollar)"
                                />
                                <select
                                  onChange={(e) =>
                                    handleFeeChange(e, fee.id, index)
                                  }
                                  name="currency"
                                  id=""
                                >
                                  <option value="USD">USD</option>
                                  <option value="AED">AED</option>
                                  <option value="AZN">AZN</option>
                                  <option value="EUR">EUR</option>
                                  <option value="GBP">GBP</option>
                                  <option value="RUB">RUB</option>
                                  <option value="BYN">BYN</option>
                                  <option value="KZT">KZT</option>
                                  <option value="TRY">TRY</option>
                                  <option value="CAD">CAD</option>
                                </select>
                                <div className={styled.select_type}>
                                  <input
                                    name="is_online"
                                    onChange={(e) =>
                                      handleFeeChangeOnline(e, fee.id, index)
                                    }
                                    type="checkbox"
                                  />
                                  <span>Online</span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className="row">
            <div className="col-xl-12">
              <div className={styled.add_additions}>
                <div className={styled.additional_list}></div>
                <button onClick={handleAdd}>
                  {addLoading ? (
                    <ThreeDots
                      height="20"
                      width="20"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <Icons name="plus" />
                  )}
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className={styled.add_additions}>
                <button
                  disabled={submitLoading ? true : false}
                  onClick={handleSubmit(OnSubmit)}
                >
                  {submitLoading ? (
                    <ThreeDots
                      height="20"
                      width="30"
                      radius="9"
                      color="#ffff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <Icons name="save" />
                  )}
                  Save Combination
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddCombination;
