import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div>
      <h1>Page is Not Found</h1>
      <h1>404</h1>
      <Link to="/">Go Home</Link>
    </div>
  );
};

export default Error;
