import React from "react";
import { Link } from "react-router-dom";
import Icons from "../../Components/Atoms/Icons";
import styled from "./Navabar.module.scss";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const Navbar = () => {
  return (
    <nav className={styled.navbar}>
      <div className={styled.logo}>
        <img src={BASE_URL + "static/images/logo/logo.png"} alt="logo" />
        <h2>Pickvisa</h2>
      </div>
      <ul className={styled.list}>
        <li>
          <Icons name="sitemap" />
          <span>
            <Link to="/combination">Visa infos</Link>
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
