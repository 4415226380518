import React, { useState, useEffect } from "react";
import Icons from "../../Components/Atoms/Icons";
import Navbar from "../Navbar/Navbar";
import styled from "./sidebar.module.scss";

const Sidebar = ({ children }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, [show]);
  const HandleToTop = () => {
    window.scrollTo(0, 0, {
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-2 col-md-6 col-sm-12 py-2">
            <Navbar />
          </div>
          <div className="col-xl-10 col-md-6 col-sm-12 mt-5 py-4">
            <div className="row">{children}</div>
            <div onClick={HandleToTop} className={styled.to_top}>
              {show ? (
                <button>
                  <Icons name="angle-up" />
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
