import { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [loading, setIsLoading] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const data = {
    loading,
    setIsLoading,
    form,
    setForm,
    token,
    setToken,
    handleLogin: async (e) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}api/v1/signin`, form)
        .then((response) => {
          if (response.status === 200 || response.status === 400) {
            if (response.data.token) {
              setToken(localStorage.setItem("token", response.data.token));
              localStorage.setItem("username", JSON.stringify(form.username));
              navigate("/combination");
              toast.success(" You are successfully logged in ");
            } else {
              navigate("/");
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error("Email or password is incorrect");
        });
    },
  };

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};
