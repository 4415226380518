import AddCombination from "./Components/Combinations/AddCombinations/AddCombination";
import CombinationDetail from "./Components/Combinations/CombinationEdit/CombinationEdit";
import CombinationsList from "./Components/Combinations/CombinationsList";
import CombinationCopy from "./Components/Combinations/CombinationCopy/CombinationCopy";
import Error from "./Pages/Error";
import Login from "./Pages/Login";

export const router = [
  {
    path: "/",
    component: <Login />,
  },

  {
    path: "/combination",
    component: <CombinationsList />,
  },
  {
    path: "/combination/:id",
    component: <CombinationDetail />,
  },
  {
    path: "/combination/copy/:id",
    component: <CombinationCopy />,
  },
  {
    path: "/add",
    component: <AddCombination />,
  },
  {
    path: "/error",
    component: <Error />,
  },
];
