import Icons from "../Atoms/Icons";
import styled from "./CombinationList.module.scss";
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

const CombinationsList = () => {
  const pageSize = 10;
  const navigate = useNavigate();
  const [customers, setCustomers] = useState(null);
  const [filters, setFilters] = useState({
    citizen_of: { value: "" },
    residents_of: { value: "" },
    travel_to: { value: "" },
    visa_type: { value: "" },
  });
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [countries, setCountries] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({
    citizen_of: "",
    residents_of: "",
    travel_to: "",
    visa_type: "",
  });

  useEffect(() => {
    GetCombinations();
    getCountries();
    getVisaTypes();
  }, []);

  useEffect(() => {
    setLoading(true);
    GetCombinations();
  }, [currentPage, rowsPerPage, filters]);

  const BASE_URL = process.env.REACT_APP_BASE_API_URL;
  const API_URL = BASE_URL + `api/v2/combinations`;

  const GetCombinations = async () => {
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const {
      citizen_of: { value: citizen_of },
      residents_of: { value: residents_of },
      travel_to: { value: travel_to },
      visa_type: { value: visa_type },
    } = filters;
    // const URL = `${API_URL}?limit=${rowsPerPage}&offset=${
    //     currentPage * rowsPerPage
    // }`;
    const URL =
      API_URL +
      "?limit=" +
      rowsPerPage +
      "&offset=" +
      currentPage * rowsPerPage +
      "&citizen_of=" +
      citizen_of +
      "&resident_of=" +
      residents_of +
      "&travel_to=" +
      travel_to +
      "&visa_type=" +
      visa_type;

    fetch(URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCustomers(data.data);
        setTotalRecords(data.total);
        setLoading(false);
      });
  };

  const getCountries = async () => {
    const API_URL = BASE_URL + "api/v1/countries";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const countryOptions = Object.values(data.data).map((country) => {
          return {
            name: country.name,
            id: country.alpha_3_code,
          };
        });
        setCountries(countryOptions);
      });
  };

  const getVisaTypes = async () => {
    const API_URL = BASE_URL + "api/v1/visa-types";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setVisaTypes(
          data.map((v) => ({
            id: v.id,
            name: v.title,
          }))
        );
      });
  };

  const renderHeader = () => {
    return;
  };

  const filterElement = (filterName, options, placeholder) => {
    return (
      <div className="row align-items-center">
        <div className="col-10">
          <Dropdown
            value={selectedFilterValues[filterName]}
            optionLabel="name"
            filter
            placeholder={`Select a ${placeholder}`}
            options={options}
            onChange={(e) => {
              handleFilterSelect(e.value, filterName);
            }}
          ></Dropdown>
        </div>
        {selectedFilterValues[filterName].id ? (
          <div className="col-1 px-0">
            <span
              onClick={() => {
                handleFilterSelect({ id: "", name: "" }, filterName);
              }}
            >
              <i className="fa fa-times-circle text-danger"></i>
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  const handleOnPage = (e) => {
    console.log(e);
    setRowsPerPage(e.rows);
    setCurrentPage(e.page);
  };

  const handleFilterSelect = (e, filterName) => {
    let newFilters = { ...filters };
    newFilters[filterName].value = e.id;
    setFilters(newFilters);
    setSelectedFilterValues((prevFilterValues) => {
      let newValues = { ...prevFilterValues };
      newValues[filterName] = e;
      return newValues;
    });
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.travel_to}</span>
      </div>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        severity="success"
      ></Button>
    );
  };

  const filterFooterTemplate = () => {
    return <div className="px-3 pt-0 pb-3 text-center">Filter by Country</div>;
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.visa_type;

    return (
      <div className="flex align-items-center gap-2">
        <span>{representative}</span>
      </div>
    );
  };
  const CitizenOf = (rowData) => {
    const representative =
      rowData.citizen_of.length > 0 ? rowData.citizen_of[0] : "";
    const length =
      rowData.citizen_of.length > 1 ? rowData.citizen_of.length - 1 : "";

    return (
      <div className="flex align-items-center gap-4">
        <span>{representative}</span>
        {length ? (
          <Tag
            style={{ marginLeft: "10px" }}
            value={length}
            severity={length}
          />
        ) : (
          ""
        )}
      </div>
    );
  };
  const ResidenceOf = (rowData) => {
    const data = rowData.residents_of.length > 0 ? rowData.residents_of[0] : "";
    const length =
      rowData.residents_of.length > 1 ? rowData.residents_of.length - 1 : "";
    const representative = data.all ? data.exceptions : data.residents_of;
    return (
      <div className="flex align-items-center gap-4">
        <span>
          {Array.isArray(representative)
            ? `${representative.slice(0, 2).join(", ")} ${
                representative.length > 2
                  ? `and ${representative.length - 2} more`
                  : ""
              }`
            : representative}
        </span>
        {length ? (
          <Tag
            style={{ marginLeft: "10px" }}
            value={length}
            severity={length}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const balanceBodyTemplate = (rowData) => {
    return <Tag value={rowData.count} severity={rowData.count} />;
  };

  const balanceFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.visa_status_v2} severity={rowData.visa_status_v2} />
    );
  };

  const handleDeleteCombinationByID = (id) => {
    const API_URL = BASE_URL + `api/v1/combinations/${id}`;
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    swal({
      title: "Are you sure?",
      text: "You wan't be able to revert this",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setCustomers(customers.filter((item) => item.id !== id));
        axios.delete(API_URL, { headers }).then((res) => {
          if (res.status === 204) {
            swal({
              title: "DONE",
              text: "Combination Deleted Successfully",
              icon: "success",
            });
          }
        });
        swal("DONE", {
          icon: "success",
        });
      } else {
        swal({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error",
        });
      }
    });
  };

  const verifiedBodyTemplate = (rowData) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <span>
          <a href={`/combination/copy/${rowData.id}`}>
            <Icons name="copy fa-lg" />
          </a>
        </span>
        <span>
          <a href={`/combination/${rowData.id}`}>
            <Icons name="edit fa-lg" />
          </a>
        </span>
        <span>
          <Icons
            onClick={() => handleDeleteCombinationByID(rowData.id)}
            name="trash-alt fa-lg"
          />
        </span>
      </div>
    );
  };

  const LogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    navigate("/");
  };
  const header = renderHeader();
  return (
    <section className={styled.combination_list}>
      <div className="container">
        <div className="row justify-content-end aloign-items-end">
          <div className="col-xl-3 d-flex text-end">
            <button
              className={`bg-opacity-75 mx-2 btn bg-success text-white ${styled.username} `}
            >
              <Icons name="user-circle" />
              {JSON.parse(localStorage.getItem("username"))}
            </button>
            <button
              className="btn bg-primary bg-opacit-75 text-white"
              onClick={LogOut}
            >
              Log Out
            </button>
          </div>
          <div className="col-xl-12 my-3">
            <h3>Combination</h3>
          </div>
          <div className="col-xl-12 my-1">
            <Link to="/add" className={styled.add_visa}>
              Add Visa Info
            </Link>
          </div>
          <div className="col-xl-12">
            <div className={styled.visa_table}>
              <DataTable
                paginator
                rowsPerPageOptions={[5, 10, 25, 50]}
                value={customers}
                first={currentPage * rowsPerPage}
                lazy
                onPage={handleOnPage}
                totalRecords={totalRecords}
                rows={rowsPerPage}
                loading={loading}
                filterDisplay="row"
                dataKey="id"
                filters={filters}
                header={header}
                emptyMessage="No Combination."
                showClearButton={false}
              >
                <Column
                  field="citizen_of"
                  header="Citizen Of"
                  filterField="citizen_of"
                  filterPlaceholder="Search by Citizen of"
                  filterElement={filterElement(
                    "citizen_of",
                    countries,
                    "Country"
                  )}
                  style={{ minWidth: "20rem" }}
                  body={CitizenOf}
                  filter
                  showClearButton={false}
                />
                <Column
                  field="residents_of"
                  header="Residence Of"
                  filter
                  filterField="residents_of"
                  filterPlaceholder="Search by Residence of"
                  filterElement={filterElement(
                    "residents_of",
                    countries,
                    "Country"
                  )}
                  style={{ minWidth: "19rem" }}
                  body={ResidenceOf}
                  showClearButton={false}
                />
                <Column
                  header="Travel To"
                  field="travel_to"
                  filter
                  filterField="travel_to"
                  filterPlaceholder="Search by Country"
                  filterElement={filterElement(
                    "travel_to",
                    countries,
                    "Country"
                  )}
                  style={{ minWidth: "18rem" }}
                  body={countryBodyTemplate}
                  showClearButton={false}
                  // filterClear={filterClearTemplate}
                  // filterApply={filterApplyTemplate}
                  // filterFooter={filterFooterTemplate}
                />
                <Column
                  header="Visa Type"
                  filterField="visa_type"
                  field="visa_type"
                  filterPlaceholder="Search by Visa Type"
                  filterElement={filterElement(
                    "visa_type",
                    visaTypes,
                    "Visa Type"
                  )}
                  body={representativeBodyTemplate}
                  filter
                  showClearButton={false}
                />

                <Column
                  header="Data Count"
                  dataType="numeric"
                  style={{ minWidth: "2rem" }}
                  body={balanceBodyTemplate}
                />
                <Column
                  field="visa_status_v2"
                  header="Status"
                  style={{ minWidth: "16rem" }}
                  body={statusBodyTemplate}
                />

                <Column
                  header="Actions"
                  bodyClassName="text-center"
                  body={verifiedBodyTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CombinationsList;
