import React from "react";
import { useState } from "react";
import styled from "./Signin.module.scss";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
const Signin = () => {
  const { token, loading, setIsLoading, form, setForm, handleLogin } =
    useContext(AuthContext);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    handleLogin();

    if (form.email === "" || form.password === "") {
      return setError("Please fill in all fields");
    } else {
      setError("");
    }
  };

  return (
    <section className={styled.signin}>
      {token}
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-8">
            <div className={styled.form_login}>
              <div className={styled.left_side}>
                <h1>
                  Log <span>In</span>
                </h1>
                <form>
                  <div className="my-3">
                    {" "}
                    {error && (
                      <p className={`text-danger m-0 ${styled.error}`}>
                        {error}
                      </p>
                    )}
                    <label htmlFor="Email">Email</label>
                    <input
                      type="text"
                      value={form.username}
                      name="username"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  {error && (
                    <p className={`text-danger m-0 ${styled.error}`}>{error}</p>
                  )}
                  <label htmlFor="Password">Password</label>
                  <input
                    value={form.password}
                    name="password"
                    onChange={handleChange}
                    type="password"
                    className="form-control"
                  />
                  {!loading ? (
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary mt-3"
                    >
                      Login
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  )}
                </form>{" "}
              </div>
              <div className={styled.right_image}>
                <img
                  src="https://images.unsplash.com/photo-1566888596782-c7f41cc184c5?ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signin;
