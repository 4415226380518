import React, { useState } from "react";
import styled from "./edit.module.scss";
import { useParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import swal from "sweetalert";
import clsx from "clsx";
import Icons from "../../Atoms/Icons";
import { useEffect } from "react";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { confirmAlert } from "react-confirm-alert";
const CombinationEdit = () => {
  const [form, setForm] = useState({
    travel_to: "",
    visa_type: "",
    additional_notes: "",
    additional_notes_az: "",
    additional_notes_ru: "",
    visa_subtypes: [],
    travel_from: [],
    documents: [],
    service_id: "",
    visa_status_v2: "",
    visa_status: "",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      travel_to: form.travel_to,
      visa_type: form.visa_type,
      additional_notes: form.additional_notes,
      additional_notes_az: form.additional_notes_az,
      additional_notes_ru: form.additional_notes_ru,
    },
  });
  const { id } = useParams();

  // const [selectedCities, setSelectedCities] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fields, setFields] = useState([]);
  // const [addCitizenofResidence, setAddCitizenofResidence] = useState([
  //   { citizens_of: "", residents_of: "" },
  // ]);
  const [checked, setChecked] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const [countries, SetCountries] = useState([]);
  // const [selectCitizenOf, setSelectCitizenOf] = useState(null);
  const [fee, setFee] = useState([]);
  // const [addSelectOption, setaddSelectOption] = useState([]);
  const [visaSubTypeChecked, setVisaSubTypeChecked] = useState([]);
  const [validty, setValidty] = useState([]);
  const [visaStatus, setVisaStatus] = useState([]);
  const [visaStatuses, setVisaStatuses] = useState([]);
  const [travelTo, setTravelTo] = useState([]);
  // const [selectedVisaType, setSelectedVisaType] = useState(null);
  const [residenceFields, setResidenceFields] = useState([
    {
      citizens_of: "",
      all: false,
      residents_of: [],
      exceptions: [],
    },
  ]);
  useEffect(() => {
    getCombinations();
    GET_API_DOCUMENTS();
    GetCountry();
    GET_VISA_TYPES();
    GetFee();
    GetValidty();
    getVisaStatuses();
  }, [id]);

  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const getCombinations = async () => {
    const BaseUrl = BASE_URL + `api/v1/combinations/${id}`;
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(BaseUrl, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setForm({
          ...data,
          visa_subtypes: data.combination_attrs.map((a) => formatSubtype(a)),
        });
        initResidenceFields(data);
        setVisaStatus(data.visa_status_v2.toString());
        setChecked(data.document_names.map((d) => d.id));
        setFields(data.combination_attrs.map((a) => formatSubtype(a)));
        setVisaSubTypeChecked(
          data.combination_attrs.map((c, i) => ({
            fieldIndex: i,
            checked: c.documents,
          }))
        );
        setTravelTo(data.travel_to);
      });
  };

  const GET_API_DOCUMENTS = async () => {
    const API_URL = BASE_URL + "api/v1/documents";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSelectedCategories(data);
      });
  };
  const GetCountry = async () => {
    const API_URL = BASE_URL + "api/v1/countries";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        SetCountries(data.data);
      });
  };
  const GET_VISA_TYPES = async () => {
    const API_URL = BASE_URL + "api/v1/visa-types";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setVisaTypes(data);
      });
  };
  const getVisaStatuses = async () => {
    const API_URL = BASE_URL + "api/v1/combinations/visa-statuses";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setVisaStatuses(data);
      });
  };
  const GetFee = async () => {
    const API_URL = BASE_URL + "api/v1/fees";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFee(data);
      });
  };
  const GetValidty = async () => {
    const API_URL = BASE_URL + "api/v1/validities";
    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    fetch(API_URL, { headers })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setValidty(data);
      });
  };
  const onCategoryChange = (e, index) => {
    const updatedList = [...checked];
    if (e.target.checked) {
      updatedList.push(e.target.value.id);
    } else {
      updatedList.splice(updatedList.indexOf(e.target.value.id), 1);
    }
    setChecked([...updatedList]);
    setForm({ ...form, documents: updatedList });
  };
  const onCategoryChangeVisaSubtype = (e, index) => {
    const updatedList = [...visaSubTypeChecked];
    let field = updatedList.find((x) => x.fieldIndex === index);
    if (field == null) {
      updatedList.push({ fieldIndex: index, checked: [] });
      field = updatedList.find((x) => x.fieldIndex === index);
    }
    if (e.target.checked) {
      field.checked.push(e.target.value.id);
    } else {
      field.checked.splice(field.checked.indexOf(e.target.value.id), 1);
    }
    setVisaSubTypeChecked([...updatedList]);
    const visaSubTypes = form.visa_subtypes;
    visaSubTypes[index].documents = field.checked;
    setForm({ ...form, visa_subtypes: visaSubTypes });
  };
  const handleFeeChangeOnline = (e, feeId, index) => {
    const { name, checked } = e.target;
    const updatedFields = [...fields];
    const updateField = updatedFields[index];
    let foundFee = updateField.fees?.find((x) => x.id == feeId);

    if (foundFee == null) {
      if (!updateField.fees) updateField.fees = [];
      updateField.fees.push({
        id: feeId,
        currency: "USD",
        is_online: false,
        [name]: checked,
      });
    } else {
      foundFee[name] = checked;
      // foundFee = { ...foundFee, [name]: value };
    }
    form.visa_subtypes = updatedFields;
    setFields(updatedFields);
    setForm({ ...form });
  };
  const handleFeeChange = (e, feeId, index) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    const updateField = updatedFields[index];
    let foundFee = updateField.fees?.find((x) => x.id == feeId);
    if (foundFee == null) {
      if (!updateField.fees) updateField.fees = [];
      updateField.fees.push({
        id: feeId,
        currency: "USD",
        is_online: false,
        [name]: value,
      });
    } else {
      foundFee[name] = value;
    }
    form.visa_subtypes = updatedFields;
    setFields(updatedFields);
    setForm({ ...form });
  };
  const validity = validty.map((item) => {
    return {
      name: item.title,
      id: item.id,
    };
  });
  const handleChnage = (e, index) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    updatedFields[index] = {
      ...updatedFields[index],
      [name]: value,
    };
    form.visa_subtypes = updatedFields;
    setFields(updatedFields);
    setForm({ ...form });
  };
  const SelectCountries = Object.values(countries).map((country) => {
    return {
      name: country.name,
      id: country.alpha_3_code,
    };
  });
  const options = [
    ...SelectCountries.filter((c) => c.id !== form.travel_to || c.id === "ARE"),
  ];
  // const allSelected = SelectCountries?.length === selectedCities?.length;

  const initResidenceFields = (data) => {
    let fields = [];
    fields = Object.keys(data.travel_from).map((city) => ({
      citizens_of: city,
      all: data.travel_from[city].all,
      residents_of: data.travel_from[city]?.residents_of || [],
      exceptions: data.travel_from[city]?.exceptions || [],
    }));
    setResidenceFields([...fields]);
  };

  const SelectTravel_to = Object.values(countries);

  let travelToName = "";
  SelectTravel_to.forEach((selectTravel) => {
    if (selectTravel.alpha_3_code === form.travel_to) {
      form.travel_to = selectTravel.alpha_3_code;
      travelToName = selectTravel.name;
    }
  });

  let defaultSelectVisaType = "";
  visaTypes.forEach((visatype) => {
    if (visatype.id === form.visa_type) {
      form.visa_type = visatype.id;
      defaultSelectVisaType = visatype.title;
    }
  });
  const HandleDelete = (index) => {
    const UpdateFields = [...fields];
    UpdateFields.splice(index, 1);
    setFields(UpdateFields);
    setForm({ ...form, visa_subtypes: UpdateFields });
  };
  // const HandleDeleteEditingPage = (index) => {
  //   const UpdateFields = [...form.combination_attrs];
  //   UpdateFields.splice(index, 1);
  //   setForm(UpdateFields);
  // };
  const SingleHandleChanges = (e) => {
    const value = e.target.value;
    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  // const AddFileds = () => {
  //   setAddCitizenofResidence([
  //     ...addCitizenofResidence,
  //     { citizens_of: "", residents_of: "" },
  //   ]);
  // };
  const handleAdd = () => {
    const newField = {
      id: "",
      service_id: "",
      title: "",
      processing_value: "",
      related_processing_key: "1",
      validity: "1",
      evisa_id: "",
      additional_notes: "",
      additional_notes_ru: "",
      additional_notes_az: "",
      fees: [],
      documents: [],
    };
    const UpdateFields = [...fields, newField];
    setFields(UpdateFields);
    setForm({ ...form, visa_subtypes: UpdateFields });
  };
  // const handleResidencesChange = (value, index) => {
  //   const updatedFields = [...addCitizenofResidence];
  //   updatedFields[index].selectedCities = value;
  //   setAddCitizenofResidence(updatedFields);
  // };

  const handleCitizenSelect = (value, index) => {
    setResidenceFields((prevFields) => {
      let updatedFields = [];
      if (
        Object.keys(form.travel_from).includes(prevFields[index].citizens_of)
      ) {
        const newField = { ...prevFields[index], citizens_of: value };
        updatedFields = [...prevFields, newField];
        updatedFields[index].is_deleted = true;
      } else {
        updatedFields = [...prevFields];
        updatedFields[index].citizens_of = value;
      }
      return updatedFields;
    });
  };

  const handleResidenceSelect = (value, index) => {
    setResidenceFields((prevFields) => {
      const updatedFields = [...prevFields];
      if (value.length === options.length) {
        updatedFields[index].all = true;
        updatedFields[index].residents_of = [];
        const defaultExceptions =
          travelTo && travelTo !== "ARE" ? travelTo : [];
        updatedFields[index].exceptions = [defaultExceptions];
        return updatedFields;
      }
      updatedFields[index].residents_of = value;
      return updatedFields;
    });
  };

  const handleExceptionSelect = (value, index) => {
    setResidenceFields((prevFields) => {
      const updatedFields = [...prevFields];
      if (value.length === SelectCountries.length) {
        updatedFields[index].all = false;
        const defaultExceptions =
          travelTo && travelTo !== "ARE" ? travelTo : [];
        updatedFields[index].exceptions = [defaultExceptions];
        return updatedFields;
      }
      updatedFields[index].exceptions = value;
      return updatedFields;
    });
  };

  const handleSelectAll = (index) => {
    setResidenceFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index].all = !updatedFields[index].all;
      const defaultExceptions = travelTo && travelTo !== "ARE" ? travelTo : [];
      if (updatedFields[index].all) {
        updatedFields[index].residents_of = [];
        updatedFields[index].exceptions = [defaultExceptions];
      } else {
        updatedFields[index].exceptions = [defaultExceptions];
      }
      return updatedFields;
    });
  };

  const formatResidenceFields = (fields) => {
    const filteredFields = fields.filter(
      (f) => (!f.all && f.residents_of.length) || f.all
    );
    const formattedFields = filteredFields.reduce((result, field) => {
      const { citizens_of, is_deleted, ...rest } = field;
      result[citizens_of] = { residents_of: rest };
      if (is_deleted) {
        result[citizens_of] = {
          ...result[citizens_of],
          is_deleted: is_deleted,
        };
      }
      return result;
    }, {});
    return formattedFields;
  };

  const addResidencyFields = () => {
    setResidenceFields((prevFields) => {
      const newField = {
        citizens_of: "",
        all: false,
        residents_of: [],
        exceptions: [],
      };
      const updatedFields = [...prevFields, newField];
      return updatedFields;
    });
  };

  const HandleDeleteFields = (index) => {
    swal({
      title: "Are you sure?",
      text: "This field will be deleted",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setResidenceFields((prevFields) => {
          let updatedFields = [...prevFields]
          if (index === 'all') {
            updatedFields = updatedFields.map(field => ({...field, is_deleted: true}))
          } else {
            if (
              Object.keys(form.travel_from).includes(
                prevFields[index].citizens_of
              )
            ) {
              updatedFields = [...prevFields];
              updatedFields[index].is_deleted = true;
            } else {
              updatedFields.splice(index, 1);
            }
          }
          return updatedFields;
        });
        swal("DONE", {
          icon: "success",
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const formatSubtype = (data) => {
    const {
      id,
      service_id,
      visa_sub_type,
      processing_value,
      related_processing_key,
      validity,
      evisa_id,
      additional_notes,
      additional_notes_ru,
      additional_notes_az,
      fee_attrs,
      documents,
    } = data;
    const fees = fee_attrs.map((f) => ({
      id: f.fee,
      value: f.value,
      currency: f.currency,
      is_online: f.is_online,
    }));

    const subtype = {
      id,
      service_id,
      title: visa_sub_type,
      processing_value,
      related_processing_key,
      validity,
      evisa_id,
      additional_notes,
      additional_notes_ru,
      additional_notes_az,
      fees,
      documents,
    };
    return subtype;
  };

  const OnSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Loading",
      text: "Please wait...",
      icon: "info",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
    const newForm = {
      travel_to: form.travel_to,
      documents: checked,
      visa_status_v2: visaStatus,
      visa_status: visaStatus,
      travel_from: formatResidenceFields(residenceFields),
      visa_type: form.visa_type,
      visa_subtypes: form.visa_subtypes,
      additional_notes: form.additional_notes,
      additional_notes_ru: form.additional_notes_ru,
      additional_notes_az: form.additional_notes_az,
    };
    form.travel_to = newForm.travel_to;
    form.visa_status_v2 = visaStatus;
    form.visa_status = visaStatus;
    form.documents = checked;
    form.travel_from = newForm.travel_from;
    setForm({ ...newForm });
    delete form.combination_attrs;
    delete form.document_names;
    // console.log({ ...form });

    const headers = {
      Authorization: "Token" + " " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    axios
      .put(
        BASE_URL + `api/v2.1/combinations/${id}`,
        { ...form },
        {
          headers,
        }
      )
      .then((res) => {
        swal.close();
        if (res.status == 200) {
          toast.success("Combination saved successfully");
          getCombinations();
        }
        if (res.status == 500 && res.status == 400) {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        swal.close();
        if (err.response?.data?.non_field_errors) {
          toast.error(err.response?.data?.non_field_errors[0]?.toString());
          confirmAlert({
            title: (
              <h1 style={{ fontSize: "14px", lineHeight: "25px" }}>
                {err.response?.data?.non_field_errors[0]?.toString()}
              </h1>
            ),
            message: "Are you sure to overwrite?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  axios
                    .post(
                      BASE_URL + `api/v1/combinations`,
                      { ...form, overwrite: true },
                      {
                        headers,
                      }
                    )
                    .then((res) => {
                      if (res.status == 201) {
                        toast.success("Combination Overwrite successfully");
                      }
                    });
                },
              },
              {
                label: "No",
              },
            ],
          });
        } else {
          swal("Error", `Something went wrong`, "error");
        }
        console.log(err.response?.data?.non_field_errors);
      });
  };

  return (
    <section className="detail">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4>Visa Info</h4>
          </div>
          <div className="col-12">
            <button className="btn btn-danger" onClick={() => HandleDeleteFields('all')}>Delete All</button>
          </div>
        </div>
      </div>
      <form action="" onSubmit={OnSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className={styled.visa_info}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xl-6">
                      <label htmlFor="name">Travel to</label>
                      <input
                        {...fields}
                        value={travelToName}
                        name="travel_to"
                        placeholder="Select a Country"
                        className="w-full p-2 w-100"
                        readOnly
                        disabled={true}
                      />
                      <input type="hidden" value={form.travel_to} />
                    </div>
                    <div className="col-xl-5">
                      <label htmlFor="name">Visa Type</label>
                      <input
                        {...fields}
                        value={defaultSelectVisaType}
                        name="visa_type"
                        placeholder="Select a visa type"
                        className="w-full p-2 w-100"
                        readOnly
                        disabled={true}
                      />
                      <input type="hidden" value={form.visa_type} />
                    </div>
                    {residenceFields.map((field, index) => {
                      if (!field.is_deleted) {
                        return (
                          <div className="row align-items-center" key={index}>
                            <div className="col-xl-6">
                              <label htmlFor="citizen_of">Citizens Of</label>
                              <Dropdown
                                value={options.find(
                                  (c) => c.id === field.citizens_of
                                )}
                                onChange={(e) => {
                                  handleCitizenSelect(e.value.id, index);
                                }}
                                options={SelectCountries.filter(
                                  (c) =>
                                    !residenceFields.some(
                                      (f) =>
                                        f.citizens_of === c.id &&
                                        f !== field &&
                                        !f.is_deleted
                                    ) && c.id !== form.travel_to
                                )}
                                name="citizen_of"
                                optionLabel="name"
                                placeholder={"Select a country"}
                                className="w-full w-100"
                                filter
                                required
                              />
                            </div>
                            <div className="col-xl-5">
                              <div className={styled.residence}>
                                {field.all ? (
                                  <label htmlFor="citizen_of">
                                    {" "}
                                    Select Exception Residents Of
                                  </label>
                                ) : (
                                  <label htmlFor="citizen_of">
                                    Residence Of
                                  </label>
                                )}
                                <div className="checked">
                                  <input
                                    type="checkbox"
                                    checked={field.all}
                                    onChange={() => {
                                      handleSelectAll(index);
                                    }}
                                  />
                                  <label>
                                    {field.all ? "All" : "Select All"}
                                  </label>
                                </div>
                              </div>
                              <div>
                                {field.all ? (
                                  <MultiSelect
                                    value={SelectCountries.filter((o) =>
                                      field.exceptions?.includes(o.id)
                                    )}
                                    options={SelectCountries}
                                    onChange={(e) =>
                                      handleExceptionSelect(
                                        e.value.map((v) => v.id),
                                        index
                                      )
                                    }
                                    filter
                                    optionLabel="name"
                                    placeholder="Select Exception Residents Of"
                                    maxSelectedLabels={3}
                                    className="w-full w-100 my-3"
                                    display="chip"
                                  />
                                ) : null}
                              </div>
                              {field.all ? null : (
                                <MultiSelect
                                  value={options.filter((o) =>
                                    field.residents_of.includes(o.id)
                                  )}
                                  onChange={(e) =>
                                    handleResidenceSelect(
                                      e.value.map((v) => v.id),
                                      index
                                    )
                                  }
                                  options={options}
                                  filter
                                  optionLabel="name"
                                  placeholder="Select Countries"
                                  maxSelectedLabels={3}
                                  className="w-full w-100"
                                  required
                                />
                              )}
                            </div>
                            {/* {index > 0 && ( */}
                            <div className="col-xl-1">
                              <button
                                type="button"
                                onClick={() => HandleDeleteFields(index)}
                                className={styled.delete_btn}
                              >
                                <Icons name="times" />
                              </button>
                            </div>
                            {/* )} */}
                          </div>
                        );
                      }
                    })}
                    <div className="col-xl-12">
                      <button
                        onClick={addResidencyFields}
                        className={clsx("my-4", styled.add_btn)}
                      >
                        Add <Icons name="plus" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="col-xl-12 py-4">
                    <label htmlFor="citizen_of">Visa Status</label>

                    <div className={styled.all_radio_list}>
                      {visaStatuses.map((label, index) => (
                        <div
                          key={index}
                          className={clsx("form-check", styled.select_radio)}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="visa_status_v2"
                            onChange={(e) => setVisaStatus(label.id.toString())}
                            id={label.id}
                            checked={label.id.toString() === visaStatus}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={label.id}
                          >
                            {label.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className={styled.additional_visa_info}>
                <h3>Additional notes for current Visa Info</h3>
                <div className="row">
                  <div className="col-xl-6">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          English
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Russian
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          Azerbaijan
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabIndex="0"
                      >
                        <textarea
                          value={form.additional_notes}
                          name="additional_notes"
                          onChange={SingleHandleChanges}
                          // defaultValue={form.additional_notes}
                          id=""
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabIndex="0"
                      >
                        <textarea
                          onChange={SingleHandleChanges}
                          value={form.additional_notes_ru}
                          // defaultValue={form.additional_notes_ru}
                          name="additional_notes_ru"
                          id=""
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                        tabIndex="0"
                      >
                        <textarea
                          onChange={SingleHandleChanges}
                          value={form.additional_notes_az}
                          // defaultValue={form.additional_notes_az}
                          name="additional_notes_az"
                          id=""
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1">
                    <h5> Documents Lists</h5>
                    <ul className={styled.documents_lists}>
                      <li>
                        {selectedCategories &&
                          selectedCategories.map((category, categoryIndex) => {
                            return (
                              <React.Fragment key={category.id}>
                                <div className="flex align-items-center my-2">
                                  <Checkbox
                                    inputId={category.key}
                                    name="category"
                                    value={category}
                                    onChange={onCategoryChange}
                                    className="mx-2"
                                    checked={checked.includes(category.id)}
                                  />
                                  <label htmlFor={category.key} className="m-0">
                                    {category.title}
                                  </label>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {fields &&
              fields.map((el, index) => (
                <div key={index} className="row">
                  <div className="col-xl-12">
                    <div className={styled.add_additions}>
                      <div className={styled.additional_list}>
                        <button onClick={() => HandleDelete(index)}>
                          Delete <Icons name="times" />
                        </button>
                        <div className="row  align-items-baseline">
                          <div className="col-xl-6">
                            <div className="row align-items-center">
                              <div className="col-xl-6">
                                <label htmlFor={`id_${index}`}>
                                  Service ID
                                </label>
                                <input
                                  disabled
                                  value={el.id}
                                  name="id"
                                  type="text"
                                />
                                <input type="hidden" value={form.id} />
                              </div>
                              <div className="col-xl-6">
                                <label htmlFor={`service_id_${index}`}>
                                  Service ID II
                                </label>
                                <input
                                  value={el.service_id}
                                  onChange={(e) => handleChnage(e, index)}
                                  name="service_id"
                                  type="number"
                                />
                              </div>
                              <div className="col-xl-8">
                                <label htmlFor="processing_time">
                                  PROCESSING TIME
                                </label>
                                <div className={styled.processing_time}>
                                  <input
                                    {...register("processing_value", {
                                      required: true,
                                    })}
                                    value={el.processing_value}
                                    name="processing_value"
                                    onChange={(e) => handleChnage(e, index)}
                                    type="text"
                                    required
                                  />

                                  <select
                                    name="related_processing_key"
                                    value={el.related_processing_key}
                                    onChange={(e) => handleChnage(e, index)}
                                  >
                                    <option value="1">Buisness Days</option>
                                    <option value="2">Calenar Days</option>
                                    <option value="3">Working Hours</option>
                                    <option value="4">Weeks</option>
                                  </select>
                                </div>
                                {errors.processing_value && (
                                  <p className="error_fields">
                                    This field is required
                                  </p>
                                )}
                              </div>
                              <div className="col-xl-4">
                                <label htmlFor="Title">Title</label>
                                <input
                                  value={el.title}
                                  onChange={(e) => handleChnage(e, index)}
                                  name="title"
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="col-xl-6">
                                <label htmlFor="processing_time">
                                  VALIDITY
                                </label>
                                <select
                                  name="validity"
                                  value={el.validity}
                                  onChange={(e) => handleChnage(e, index)}
                                >
                                  {validity.map((validty) => (
                                    <option value={validty.id}>
                                      {validty.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-xl-6">
                                <label htmlFor={`evisa_id-${index}`}>
                                  E_VISA ID
                                </label>
                                <input
                                  {...register("evisa_id")}
                                  type="number"
                                  onChange={(e) => handleChnage(e, index)}
                                  name="evisa_id"
                                  defaultValue={el.evisa_id}
                                />
                                {errors.evisa_id && (
                                  <p className="error_fields">
                                    This field is required
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row mt-5">
                              <div className="col-xl-12">
                                <div className={styled.lang_text}>
                                  <nav>
                                    <div
                                      className="nav nav-tabs"
                                      id={`nav-tab-${index}`}
                                      role="tablist"
                                    >
                                      <button
                                        className="nav-link active"
                                        id={`nav-eng-tab-${index}`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-eng-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-eng-${index}`}
                                        aria-selected="true"
                                      >
                                        English
                                      </button>
                                      <button
                                        className="nav-link"
                                        id={`nav-rus-tab-${index}`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-rus-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-rus-${index}`}
                                        aria-selected="false"
                                      >
                                        Russian
                                      </button>
                                      <button
                                        className="nav-link"
                                        id={`nav-az-tab-${index}`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-az-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-az-${index}`}
                                        aria-selected="false"
                                      >
                                        Azerbaijan
                                      </button>
                                    </div>
                                  </nav>
                                  <div
                                    className="tab-content"
                                    id="nav-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id={`nav-eng-${index}`}
                                      role="tabpanel"
                                      aria-labelledby={`nav-eng-${index}`}
                                      tabIndex="0"
                                    >
                                      <textarea
                                        value={el.additional_notes}
                                        onChange={(e) => handleChnage(e, index)}
                                        className="m-0"
                                        name="additional_notes"
                                      ></textarea>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id={`nav-rus-${index}`}
                                      role="tabpanel"
                                      aria-labelledby={`nav-rus-${index}`}
                                      tabIndex="0"
                                    >
                                      <textarea
                                        value={el.additional_notes_ru}
                                        onChange={(e) => handleChnage(e, index)}
                                        name="additional_notes_ru"
                                        className="m-0"
                                      ></textarea>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id={`nav-az-${index}`}
                                      role="tabpanel"
                                      aria-labelledby={`nav-az-${index}`}
                                      tabIndex="0"
                                    >
                                      <textarea
                                        value={el.additional_notes_az}
                                        onChange={(e) => handleChnage(e, index)}
                                        name="additional_notes_az"
                                        className="m-0"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <h5> Documents Lists</h5>
                            <ul className={styled.documents_lists}>
                              <li>
                                {selectedCategories &&
                                  selectedCategories.map(
                                    (category, index_category) => {
                                      return (
                                        <div
                                          key={category.id}
                                          className="flex align-items-center my-2"
                                        >
                                          <Checkbox
                                            inputId={category.key}
                                            name="category"
                                            value={category}
                                            onChange={(e) =>
                                              onCategoryChangeVisaSubtype(
                                                e,
                                                index
                                              )
                                            }
                                            className="mx-2"
                                            checked={visaSubTypeChecked
                                              .find(
                                                (x) => x.fieldIndex === index
                                              )
                                              ?.checked.includes(category.id)}
                                          />
                                          <label
                                            htmlFor={category.key}
                                            className="m-0"
                                          >
                                            {category.title}
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row my-4 py-4">
                          {fee &&
                            fee.map((fee, feeIndex) => (
                              <div key={feeIndex} className="col-xl-6 my-1">
                                <label htmlFor="">{fee.title || ""}</label>
                                <div
                                  className={clsx(
                                    "input-group mb-3",
                                    styled.input_group
                                  )}
                                >
                                  <span className="input-group-text">$</span>
                                  <input
                                    type="text"
                                    defaultValue={
                                      el.fees?.find((f) => f.id === fee.id)
                                        ?.value
                                    }
                                    onChange={(e) =>
                                      handleFeeChange(e, fee.id, index)
                                    }
                                    name={`value`}
                                    aria-label="Amount (to the nearest dollar)"
                                  />
                                  <select
                                    onChange={(e) =>
                                      handleFeeChange(e, fee.id, index)
                                    }
                                    name="currency"
                                    defaultValue={
                                      el.fees?.find((f) => f.id === fee.id)
                                        ?.currency || "USD"
                                    }
                                    id=""
                                  >
                                    <option value="USD">USD</option>
                                    <option value="AED">AED</option>
                                    <option value="AZN">AZN</option>
                                    <option value="EUR">EUR</option>
                                    <option value="GBP">GBP</option>
                                    <option value="RUB">RUB</option>
                                    <option value="BYN">BYN</option>
                                    <option value="KZT">KZT</option>
                                    <option value="TRY">TRY</option>
                                    <option value="CAD">CAD</option>
                                  </select>
                                  <div className={styled.select_type}>
                                    <input
                                      name="is_online"
                                      defaultValue={
                                        el.fees?.find((f) => f.id === fee.id)
                                          ?.is_online || false
                                      }
                                      onChange={(e) =>
                                        handleFeeChangeOnline(e, fee.id, index)
                                      }
                                      type="checkbox"
                                    />
                                    <span>Online</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="row">
              <div className="col-xl-12">
                <div className={styled.add_additions}>
                  <div className={styled.additional_list}></div>
                  <button onClick={handleAdd}>
                    <Icons name="plus" />
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className={styled.add_additions}>
                <button type="submit">
                  <Icons name="save" />
                  Save Combination
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default CombinationEdit;
