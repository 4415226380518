import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./Layout/Sidebar/Sidebar";
import { Routes, Route } from "react-router-dom";
import { router } from "./router";
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  return (
    <div>
      <ToastContainer
        progressClassName="toastProgress"
        bodyClassName="toastBody"
        position="top-right"
      />
      {location.pathname === "/" ? (
        <Routes>
          {router &&
            router.map((route, index) => (
              <Route
                element={route.component}
                path={token ? route.path : "/"}
                key={index}
              />
            ))}
        </Routes>
      ) : (
        token && (
          <Sidebar>
            <Routes>
              {router &&
                router.map((route, index) => (
                  <Route
                    element={route.component}
                    path={route.path}
                    key={index}
                  />
                ))}
            </Routes>
          </Sidebar>
        )
      )}
    </div>
  );
};

export default App;
